import React from 'react'
import styled from 'styled-components'
import * as meme from '../assets/soon.jpg'

export default () => (
  <Container>
    <Meme />
  </Container>
)

const Meme = styled.div`
  background-image: url(${meme});
  background-repeat: no-repeat;
  background-position: center;
  justify-self: center;
  height: 331px;
  width: 100%;
  grid-column: 2;
`

const Container = styled.div`
  display: grid;
  height: 100vh;
  align-content: center;
  grid-template: auto / 25px auto 25px;
  background-color: #eee;
  > p {
    text-align: center;
    font: 3rem / 1.2rem 'Livvic', sans-serif;
    font-weight: 600;
  }
`
